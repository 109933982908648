import type { ReactNode } from 'react';

import { chatStyle } from './index.css';

export interface ChatProps {
  children?: ReactNode;
}

export function Chat({ children }: ChatProps) {
  return <div className={chatStyle}>{children}</div>;
}
