import { notify } from '@affine/component';
import { AuthInput, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { useI18n } from '@affine/i18n';
import { ArrowRightBigIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import clsx from 'clsx';
import type { FC } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthService, ServerConfigService } from '../../../modules/cloud';
import { emailRegex } from '../../../utils/email-regex';
import type { AuthPanelProps } from './index';
import { OAuth } from './oauth';
import * as style from './style.css';
import { Captcha, useCaptcha } from './use-captcha';

function validateEmail(email: string) {
  return emailRegex.test(email);
}

//DNB_FIX добавить Legal
const Legal = () => {
  return (
    <div className={clsx(style.legalContainerStyle, style.authMessage)}>
      {'Я согласен с '}
      <a
        href="https://mkskom.ru/legal-documents#agree"
        target="_blank"
        rel="noreferrer"
      >
        Пользовательским соглашением
      </a>
      {' и '}
      <a
        href="https://mkskom.ru/legal-documents#policy"
        target="_blank"
        rel="noreferrer"
      >
        Политикой конфиденциальности
      </a>
    </div>
  );
};

export const SignIn: FC<AuthPanelProps<'signIn'>> = ({
  setAuthData: setAuthState,
}) => {
  const t = useI18n();
  const authService = useService(AuthService);
  const [searchParams] = useSearchParams();
  const [isMutating, setIsMutating] = useState(false);
  const [verifyToken, challenge, refreshChallenge] = useCaptcha();

  const serverConfig = useService(ServerConfigService).serverConfig;
  const enabledAuthEmail = useLiveData(
    serverConfig.config$.map(r => r?.enabledAuthEmail)
  );

  const [email, setEmail] = useState('');

  const [isValidEmail, setIsValidEmail] = useState(true);
  const errorMsg = searchParams.get('error');

  const onContinue = useAsyncCallback(async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    setIsValidEmail(true);
    setIsMutating(true);

    try {
      const { hasPassword, registered } =
        await authService.checkUserByEmail(email);

      if (verifyToken) {
        if (registered) {
          // provider password sign-in if user has by default
          //  If with payment, onl support email sign in to avoid redirect to affine app
          if (hasPassword) {
            refreshChallenge?.();
            setAuthState({
              state: 'signInWithPassword',
              email,
            });
          } else {
            await authService.sendEmailMagicLink(email, verifyToken, challenge);
            setAuthState({
              state: 'afterSignInSendEmail',
              email,
            });
          }
        } else {
          await authService.sendEmailMagicLink(email, verifyToken, challenge);
          setAuthState({
            state: 'afterSignUpSendEmail',
            email,
          });
        }
      }
    } catch (err) {
      console.error(err);

      // TODO(@eyhn): better error handling
      notify.error({
        title: 'Ошибка отправки email. Пожалуйста, попробуйте снова.',
      });
    }

    setIsMutating(false);
  }, [
    authService,
    challenge,
    email,
    refreshChallenge,
    setAuthState,
    verifyToken,
  ]);

  return (
    <>
      <ModalHeader
        title={`${t['com.affine.auth.sign.in']()} ${t['com.affine.brand.affineCloud']()}`}
        subTitle={''}
      />
      {/* DNB_FIX */}
      <p className={style.authModalText}>
        Мы выполним вход или создадим учетную запись, если у Вас ее еще нет
      </p>

      <OAuth />

      {enabledAuthEmail && (
        <div className={style.authModalContent}>
          <AuthInput
            label={t['com.affine.settings.email']()}
            placeholder={t['com.affine.auth.sign.email.placeholder']()}
            onChange={setEmail}
            error={!isValidEmail}
            errorHint={
              isValidEmail ? '' : t['com.affine.auth.sign.email.error']()
            }
            onEnter={onContinue}
          />

          {verifyToken ? (
            <Button
              size="extraLarge"
              variant="primary"
              //DNB_FIX цвет и disabled
              disabled={!email}
              style={{
                width: '100%',
                backgroundColor: !email ? '#C9C9C9' : '',
              }}
              data-testid="continue-login-button"
              block
              loading={isMutating}
              suffix={
                <ArrowRightBigIcon
                  width={20}
                  height={20}
                  style={{
                    transform: 'rotate(-90deg)',
                    //DNB_FIX цвет
                    color: 'var(--affine-white)',
                  }}
                />
              }
              suffixStyle={{ width: 20, height: 20, color: cssVar('blue') }}
              onClick={onContinue}
            >
              {t['com.affine.auth.sign.email.continue']()}
            </Button>
          ) : (
            <Captcha />
          )}

          {errorMsg && <div className={style.errorMessage}>{errorMsg}</div>}
        </div>
      )}
      {/* DNB_FIX */}
      {/* DNB_FIX */}
      <Legal />
    </>
  );
};
