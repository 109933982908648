import { toast } from '@affine/component';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { OrganizeService } from '@affine/core/modules/organize';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { DocxTransformer } from '@affine/core/utils';
import { useI18n } from '@affine/i18n';
import type { DocMode } from '@blocksuite/affine/blocks';
import type { DocCollection } from '@blocksuite/affine/store';
import { type DocProps, DocsService, FeatureFlagService, useService, useServices } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';

export const usePageHelper = (docCollection: DocCollection) => {
  const t = useI18n();

  const {
    docsService,
    workbenchService,
    editorSettingService,
    organizeService,
  } = useServices({
    DocsService,
    WorkbenchService,
    EditorSettingService,
    OrganizeService,
  });
  const workbench = workbenchService.workbench;
  const docRecordList = docsService.list;

  const folderTree = organizeService.folderTree;
  const rootFolder = folderTree.rootFolder;

  const featureFlagService = useService(FeatureFlagService);

  // DNB_FIX добавить параметр pageId и создать document
  const createPageAndOpen = useCallback(
    (mode?: DocMode, open?: boolean | 'new-tab') => {
      const docProps: DocProps = {
        note: editorSettingService.editorSetting.get('affine:note'),
      };
      const page = docsService.createDoc({ docProps });
      if (mode) {
        docRecordList.doc$(page.id).value?.setPrimaryMode(mode);
      }

      if (open !== false)
        workbench.openDoc(page.id, {
          at: open === 'new-tab' ? 'new-tab' : 'active',
        });
      return page;
    },
    [docRecordList, docsService, editorSettingService, workbench]
  );

  const createEdgelessAndOpen = useCallback(
    (open?: boolean | 'new-tab') => {
      return createPageAndOpen('edgeless', open);
    },
    [createPageAndOpen]
  );

  const importFileAndOpen = useMemo(
    () => async () => {
      const { showImportModal } = await import('@blocksuite/affine/blocks');
      const { promise, resolve, reject } =
        Promise.withResolvers<
          Parameters<
            NonNullable<Parameters<typeof showImportModal>[0]['onSuccess']>
          >[1]
        >();
      const onSuccess = (
        pageIds: string[],
        options: { isWorkspaceFile: boolean; importedCount: number }
      ) => {
        resolve(options);

        // DNB_FIX выводить сообщение на ru
        toast(`Успешно импортировался(-ись) документ(-ы)`);
        if (options.isWorkspaceFile) {
          workbench.openAll();
          return;
        }

        if (pageIds.length === 0) {
          return;
        }
        const pageId = pageIds[0];

        // DNB_FIX добавлять папку и документ в "Каталог"
        const title = docRecordList.doc$(pageId).value?.title$.value;
        const folderId = rootFolder.createFolder(
          title || t['com.affine.rootAppSidebar.organize.new-folders'](),
          rootFolder.indexAt('after')
        );

        const folerNode = folderTree.folderNode$(folderId).value;
        folerNode?.createLink('doc', pageId, folerNode.indexAt('after'));

        workbench.openDoc(pageId);
      };
      showImportModal({
        collection: docCollection,
        onSuccess,
        onFail: message => {
          reject(new Error(message));
        },
        functionImport: (type, options) => {
          const importDocx = async () => {
            let pages: { pageIds: string[] } = { pageIds: [] };

            if (
              options?.collection &&
              options?.file &&
              options.file instanceof File
            ) {
              pages = await DocxTransformer.importDocx(
                options?.collection,
                options?.file,
                {
                  exp: featureFlagService.flags.enable_experimental_import_export.value,
                }
              );
            }
            return { pageIds: pages.pageIds, entryId: '' };
          };
          if (type === 'docx') {
            return importDocx;
          } else {
            return null;
          }
        },
      });
      return await promise;
    },
    [
      docCollection,
      workbench,
      rootFolder,
      t,
      docRecordList,
      folderTree,
      featureFlagService.flags,
    ]
  );

  return useMemo(() => {
    return {
      createPage: (mode?: DocMode, open?: boolean | 'new-tab') =>
        createPageAndOpen(mode, open),
      createEdgeless: createEdgelessAndOpen,
      importFile: importFileAndOpen,
    };
  }, [createEdgelessAndOpen, createPageAndOpen, importFileAndOpen]);
};
