import { useQuery } from '@tanstack/react-query';

import { getBoards, isAuthorized } from './api';

export const useGetBoards = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: ['miro', 'boards'],
    queryFn: () => getBoards(),
    enabled: options?.enabled,
  });

export const useIsAuthorized = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: ['miro', 'authorize'],
    queryFn: () => isAuthorized(),
    enabled: options?.enabled,
  });
