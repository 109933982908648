import type { SVGProps } from 'react';

export const MiroLogoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      fill="currentColor"
      width={props.width || '1em'}
      height={props.height || '1em'}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.728 1.024h-4.356l3.868 6-8.182-6h-4.362l3.872 7.862-8.182-7.862h-4.362l3.806 10.148-3.806 19.804h4.362l8.18-21.234-3.87 21.234h4.36l8.184-23.096-3.87 23.096h4.358l8.248-25.368-8.248-4.584z"></path>
    </svg>
  );
};
