import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW3W6bMBi971NYrSZtF46Akp9S9aZSn2KaJgd/gBtjI9uk6aa9+2QDiSHQQG4i2d/P8fH5jln9DtP3bXyIA/T3DqGSqJwJbGSVoMegOj3f/btbdSGhF5IgUhv5fEnZS2NkmaDYJiFk4GQw4SwXCUpBGFC9UtFEN4RSyaVK0JGo7xiTLGMCsCumIZWCEvWJXcgPG5xJYbBmf2CQ0KyXLobbhQJYXpgEhat1D8fjchwFywtuq+FMKsiVrAXFCuhtQCd9G1CEiIPUtn8I3542b6+DkBVn4tCE1UrbuEqyhuNx4Db+TJtXKnZFKqmZYdLe6V5LXhuwdVQDMHgeEISjzUAXa5/ETgdX6tn455pGh9CepIeG1gQZRYSuiAJh3JZUFBRuiww2F3MfRc1V1xoU1sAhNQkSUkAP99bhnqO0RdK9TXrHZNCDs3NwuiNst80RKNMVJ58Jyji4BfuPKVOQNk1SyetS2J33WhuWWSjCgDCX8UTIzStmBkrtL39pCk8Oz1X/L7rkpErQrsE9eh+3ufb6k0lZOeorxcoRz/hoW26CPrt7V+2DUVMkKF7P8rK0lxNM58xTUcs2h8zaw2bajfa8husTxYMTUYduHM6SW5vQxpyh6+FbB1eOsml5Hr4kQ6nBuNTmQDhLLPAkNoA000urWgH+KJiBgVvh0UtSpNtpxp5QykSeoKB7KVtPU4SyWp/fz6U34dP0kxJDMNN4D5zBEdTLvVE13P9y/F0DfnjcxuE67BXJJmXjBeUXa+zJb5FdPuzcb3po2snarb9dTq+BZyMW1UknDHqKcioLh3IqxuXk7ClsrWhC9UM59fpEo2pulr32rCGPwylBoV808FRxzq5OSEvOaJ/OY4Q5+QSFmdCMwmuXZP/6Nvk+aZPHaPBIzfyQ8YofbhZvbXj2N9L1fHrt+M129oti+THKuXW9HDHhSZPP7/j0/gc/DcFVhwsAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_1cj74k4d';
export var authMessage = '_1cj74k42';
export var authModalContent = '_1cj74k40';
export var authModalText = '_1cj74k4g';
export var captchaWrapper = '_1cj74k41';
export var errorMessage = '_1cj74k43';
export var forgetPasswordButton = '_1cj74k47';
export var forgetPasswordButtonRow = '_1cj74k44';
export var legalContainerStyle = '_1cj74k4f';
export var linkButton = '_1cj74k46';
export var resendCountdown = '_1cj74k4b';
export var resendCountdownInButton = '_1cj74k4c';
export var resendWrapper = '_1cj74k48';
export var sendMagicLinkButtonRow = '_1cj74k45';
export var sentMessage = '_1cj74k4a';
export var sentRow = '_1cj74k49';
export var skipDivider = '_1cj74k4h';
export var skipDividerLine = '_1cj74k4i';
export var skipDividerText = '_1cj74k4j';
export var skipLink = '_1cj74k4l';
export var skipLinkIcon = '_1cj74k4m';
export var skipSection = '_1cj74k4n';
export var skipText = '_1cj74k4k';
export var userPlanButton = '_1cj74k4e';