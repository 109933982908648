import type { DocMeta } from '@blocksuite/affine/store';
import {
  effect,
  Entity,
  LiveData,
  onComplete,
  onStart,
} from '@toeverything/infra';
import { truncate } from 'lodash-es';
import { EMPTY, map, mergeMap, of, switchMap } from 'rxjs';

import type { DocDisplayMetaService } from '../../doc-display-meta';
import type { WorkspacesSearchService } from '../../workspaces-search';
import type { QuickSearchSession } from '../providers/quick-search-provider';
import type { QuickSearchItem } from '../types/item';

interface WorkspacesDocsPayload {
  docId: string;
  title?: string;
  blockId?: string | undefined;
  blockContent?: string | undefined;
  docMeta?: DocMeta | undefined;
}

export class WorkspacesDocsQuickSearchSession
  extends Entity
  implements QuickSearchSession<'docs', WorkspacesDocsPayload>
{
  constructor(
    private readonly docDisplayMetaService: DocDisplayMetaService,
    private readonly workspacesSearchService: WorkspacesSearchService
  ) {
    super();
  }

  private readonly isQueryLoading$ = new LiveData(false);

  query$ = new LiveData('');

  items$ = new LiveData<QuickSearchItem<'docs', WorkspacesDocsPayload>[]>([]);

  query = effect(
    switchMap((query: string) => {
      let out;
      if (!query) {
        out = of([] as QuickSearchItem<'docs', WorkspacesDocsPayload>[]);
      } else {
        out = this.workspacesSearchService.search$(query).pipe(
          map(docs =>
            docs
              .map(doc => {
                return [doc, doc.docMeta] as const;
              })
              .filter(
                (props): props is [(typeof props)[0], DocMeta] => !!props[1]
              )
              .map(([doc, docRecord]) => {
                const { title, icon, updatedDate } =
                  this.docDisplayMetaService.getDocDisplayMeta(
                    docRecord,
                    'title' in doc ? doc.title : undefined
                  );
                return {
                  id: 'doc:' + docRecord.id,
                  source: 'docs',
                  group: {
                    id: 'docs',
                    label: {
                      key: 'com.affine.quicksearch.group.searchfor',
                      options: { query: truncate(query) },
                    },
                    score: 5,
                  },
                  label: {
                    title: title,
                    subTitle: doc.blockContent,
                  },
                  score: doc.score,
                  icon,
                  timestamp: updatedDate,
                  payload: doc,
                } as QuickSearchItem<'docs', WorkspacesDocsPayload>;
              })
          )
        );
      }
      return out.pipe(
        mergeMap((items: QuickSearchItem<'docs', WorkspacesDocsPayload>[]) => {
          this.items$.next(items);
          this.isQueryLoading$.next(false);
          return EMPTY;
        }),
        onStart(() => {
          this.items$.next([]);
          this.isQueryLoading$.next(true);
        }),
        onComplete(() => {})
      );
    })
  );

  // TODO(@EYHN): load more

  setQuery(query: string) {
    this.query$.next(query);
  }
}
