import type { InlineEditProps } from '@affine/component';
import { InlineEdit } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { track } from '@affine/track';
import {
  DocsService,
  useLiveData,
  useService,
  WorkspaceService,
} from '@toeverything/infra';
import clsx from 'clsx';
import type { HTMLAttributes } from 'react';

import { Breadcrumbs } from '../breadcrumbs';
import * as styles from './style.css';

export interface BlockSuiteHeaderTitleProps {
  docId: string;
  /** if set, title cannot be edited */
  inputHandleRef?: InlineEditProps['handleRef'];
  className?: string;
  onEditSave?: () => void;
}

const inputAttrs = {
  'data-testid': 'title-content',
} as HTMLAttributes<HTMLInputElement>;
export const BlocksuiteHeaderTitle = (props: BlockSuiteHeaderTitleProps) => {
  const { inputHandleRef, docId } = props;
  const workspaceService = useService(WorkspaceService);
  const isSharedMode = workspaceService.workspace.openOptions.isSharedMode;
  // DNB_FIX получить isReadOnly
  const isReadOnly = useLiveData(
    useService(WorkspacePermissionService).permission.isReadOnly$
  );

  const docsService = useService(DocsService);

  const docRecord = useLiveData(docsService.list.doc$(docId));
  const docTitle = useLiveData(docRecord?.title$);

  const onChange = useAsyncCallback(
    async (v: string) => {
      await docsService.changeDocTitle(docId, v);
      track.$.header.actions.renameDoc();
    },
    [docId, docsService]
  );

  return (
    // DNB_FIX не редактировать заголовок при isReadOnly
    isSharedMode ? (
      <Breadcrumbs
        className={styles.breadcrumbs}
        pageId={docId}
        title={docTitle}
      />
    ) : (
      <InlineEdit
        className={clsx(styles.title, props.className)}
        value={docTitle}
        onChange={onChange}
        editable={!isSharedMode && !isReadOnly}
        exitible={true}
        placeholder="Untitled"
        data-testid="title-edit-button"
        handleRef={inputHandleRef}
        inputAttrs={inputAttrs}
      />
    )
  );
};
