import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61XXY+bOhB9z6+welVp98EISLK79UpX/SdXAx4SN8ZGtkmyrfa/VxiTAAsJ296nBDNjnzMfZ8z3A74VBkq05L/EPicu1Wvya0VI/NX/EOIMKFtoUzJitAOHDzHH3ePripD3FSHbG4bJy8A0iW/Yrp96tu+rqEMTk0jqnfZ+hVaOWvETGTmCeaAUikIopH59T9fe2z+cUOz2jpGnOG7Wci21GTllEvKD9yjB7ISiJrhU52bxiMaJHCQFKXaKkVJwLvF1CO1fUrFCGOuoLqh7q/DvYAYgmXZOl4xsWiSy8d0Hy/SlXeTCVhLeGCkk+gUPkwqHpWUkR+XQTICV8Dmsm1msE6h6pyV+8wo4F2p3IbSOW/CVtsIJrRgxKMGJ4zCuSXQSbq9rR/dCucmd0vjDgRIylN74EptM6vwwl3+HZ0ct5lpxMG/UmzzOZ+FmoGy5ME7pCHZ6Pwlh72vMILNa1g6b1XGpYOEYWXJuhMZo40+fCo5/e4nJwPEERgm1m3UN76ecN97pJLjbM5JsW8wdxu55qjgaooajoQa4qC1rVKdZ1Uc0hdQnRvaCc1TeEvLDzuhacTrZ9u1GU+gYg8JhFxTlUDlGvnx57WFOR5jTMeZ+cnrZCNri/ztdhX9d9nrNzwjUTk/z+KcoiubNTyoUxzMj6VxkhqwyLLTBG7TSeEire74XSlnjjdps6YdMdVTD44VC4iPSTQOqjfBBaHyDjxc3JUpoT7hOqSTaWiJUIZRw6OsdhpK3vd9cGdiWwqBd5lU7aINP4XqsQVsCs10hhTpMFd3TpLxtlgnl81DuulHwo7ZOFI2khWTbCnKkGboTtj1yb1i8LBSGZdLllTaM0e6wUcSTaPvpPrqUVBurYf/0yHzzZLqT1unMAP0QtSvUyXDdKYZvJDJo0bcOx+skm5oRS4I4vKSM5y0MOCZxfNz3ZSuOj6f/ifSSdhoAi04GqirI6qevLUCigM37l3CmgdRzPA45kMgJJxdcbLzZ0mvYh8sNkMjW2fWo+/af14XsXkK/Tuaz+aVcGMzbNsq1rEv1J5nuYcn7Y3v9odL5MLNKK69S30vkAojNDaIioDh56GUvidNNdX70rqMqvsXiIpU+BtX5MqomCPpdrAPjug+Quaq8deLAjwfzEdn2k+U24W2DdMQ3D9uNsvq+ev8NhdwCUJcNAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1s71t2o6';
export var authCodeErrorMessage = '_1s71t2o8';
export var authCodeWrapper = '_1s71t2o7';
export var authContent = '_1s71t2o5';
export var authInputWrapper = '_1s71t2o1';
export var authPageContainer = '_1s71t2oa';
export var formHint = '_1s71t2o2';
export var hideInSmallScreen = '_1s71t2od';
export var input = '_1s71t2oc';
export var loading = '_1s71t2o4';
export var modalHeaderWrapper = '_1s71t2o0';
export var resendButtonWrapper = '_1s71t2o9';
export var signInPageContainer = '_1s71t2ob';