import { useJournalInfoHelper } from '@affine/core/components/hooks/use-journal';
import { useI18n } from '@affine/i18n';
import type { Doc } from '@blocksuite/affine/store';

import * as styles from './styles.css';

export const BlocksuiteEditorJournalDocTitle = ({ page }: { page: Doc }) => {
  const { localizedJournalDate, isTodayJournal, journalDate } =
    useJournalInfoHelper(page.id);
  const t = useI18n();

  const day = journalDate?.day() ?? null;
  const weekDays = [
    t['com.affine.calendar.weekdays.sun'](),
    t['com.affine.calendar.weekdays.mon'](),
    t['com.affine.calendar.weekdays.tue'](),
    t['com.affine.calendar.weekdays.wed'](),
    t['com.affine.calendar.weekdays.thu'](),
    t['com.affine.calendar.weekdays.fri'](),
    t['com.affine.calendar.weekdays.sat'](),
  ];

  return (
    <span className="doc-title-container">
      <span>{localizedJournalDate}</span>
      {isTodayJournal ? (
        <span className={styles.titleTodayTag}>{t['com.affine.today']()}</span>
      ) : (
        day !== null && (
          <span className={styles.titleDayTag}>{weekDays[day]}</span>
        )
      )}
    </span>
  );
};
