import { Service } from '@toeverything/infra';

import { FolderTree } from '../entities/folder-tree';
export class OrganizeService extends Service {
  folderTree = this.framework.createEntity(FolderTree);

  //DNB_FIX
  getAllPathFolders(id: string) {
    const result: string[] = [];

    const getPath = (id: string) => {
      const path$ = this.folderTree.folderNode$(id);

      const pathFolder$ = path$.getValue()?.info$;

      if (
        pathFolder$ &&
        pathFolder$?.value?.type !== 'doc' &&
        pathFolder$?.value?.data
      ) {
        result.push(pathFolder$?.value?.data);
      }

      if (!pathFolder$?.value?.parentId) {
        return;
      } else {
        getPath(pathFolder$?.value?.parentId);
      }
    };
    getPath(id);
    return result.reverse();
  }
}
