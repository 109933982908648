import { useQuery } from '@tanstack/react-query';
import { useService } from '@toeverything/infra';

import { AuthService } from '../services/auth';
import type { OauthPreflightData, OauthSignInData } from '../types';

export function useOauthPreflight(data: OauthPreflightData) {
  const auth = useService(AuthService);

  return useQuery({
    queryKey: [
      'auth',
      'oauth',
      'preflight',
      data.provider,
      data.client,
      data.redirectUri,
    ],
    queryFn: async () => {
      return await auth.oauthPreflight(
        data.provider,
        data.client,
        data.redirectUri
      );
    },
  });
}

export function useOauthSignIn(data: OauthSignInData) {
  const auth = useService(AuthService);

  return useQuery({
    queryKey: [
      'auth',
      'oauth',
      'sign-in',
      data.code,
      data.state,
      data.provider,
    ],
    queryFn: async () => {
      return await auth.signInOauth(data.code, data.state, data.provider);
    },
  });
}
