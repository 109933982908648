import { MenuItem } from '@affine/core/modules/app-sidebar/views';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { miroAtom } from './model';
import { MiroLogoIcon } from './ui';

export const ImportMiro = () => {
  const setMiroAtom = useSetAtom(miroAtom);

  const onClick = useCallback(() => {
    setMiroAtom(prev => ({ ...prev, openModal: true }));
  }, [setMiroAtom]);

  return (
    <MenuItem icon={<MiroLogoIcon />} onClick={onClick}>
      Импортировать из Miro
    </MenuItem>
  );
};
