import { type SVGProps } from 'react';

export const OpenWebUIIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 44}
      height={props.height || 44}
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
        <path
          d="M1866 3600 c-222 -35 -446 -146 -601 -297 -237 -230 -357 -530 -341
-853 23 -481 355 -892 821 -1017 90 -24 117 -27 280 -27 155 0 192 3 262 21
208 56 357 139 509 285 206 198 320 456 331 748 12 322 -94 596 -317 821 -206
207 -457 316 -750 324 -74 2 -162 0 -194 -5z m350 -461 c169 -51 327 -184 403
-339 96 -196 95 -397 -4 -596 -105 -213 -313 -349 -554 -361 -122 -6 -219 13
-326 66 -410 203 -499 739 -176 1061 105 105 233 169 386 194 59 9 202 -4 271
-25z"
        />
        <path
          d="M3480 2500 l0 -1110 220 0 220 0 0 1110 0 1110 -220 0 -220 0 0
-1110z"
        />
      </g>
    </svg>
  );
};

export const ChatClearIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M19.3144 5.1023C19.5618 5.34971 19.5618 5.75083 19.3144 5.99823L13.5075 11.8052C14.5763 13.2524 14.6251 15.25 13.564 16.76L11.1633 20.1764C10.9886 20.4249 10.6461 20.486 10.3964 20.3131C7.93754 18.6108 5.80584 16.4791 4.10358 14.0203C3.93067 13.7705 3.99171 13.4281 4.24025 13.2534L7.65663 10.8527C9.16673 9.79155 11.1643 9.84036 12.6116 10.9092L18.4185 5.1023C18.6659 4.8549 19.067 4.8549 19.3144 5.1023ZM10.5405 18.8589L12.5273 16.0316C12.535 16.0205 12.5427 16.0095 12.5502 15.9984C11.6977 15.6645 10.8371 15.096 10.0789 14.3378C9.3207 13.5796 8.75224 12.719 8.4183 11.8664C8.4072 11.874 8.39613 11.8816 8.38511 11.8894L5.55779 13.8761C6.96005 15.777 8.63969 17.4566 10.5405 18.8589ZM9.59298 11.3911C9.85414 12.0639 10.3184 12.7855 10.9748 13.4419C11.6312 14.0983 12.3528 14.5626 13.0255 14.8237C13.1835 13.8976 12.8979 12.9206 12.197 12.2197C11.4961 11.5187 10.5191 11.2331 9.59298 11.3911Z" />
      </g>
    </svg>
  );
};

export const EyeOpenIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
    </svg>
  );
};

export const EyeSlashIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
    </svg>
  );
};
