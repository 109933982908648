import "../../component/src/components/chats/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/chats/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U227bMAx9z1dwDwPaBwWKt/SifMygSLStVZYESo7jFv33wXaMWlmMrUCfDFHUOYeHpLe/dv3T854jh7cNQGd0qgXsOP9+2ADUaKo6fZy1icHKXkBp8TwEhi/ThlAl450A5W3buOHmdxuTKXumvEvokoAYpEJ2xNQhusPmfbOdqXe3qD9B5U9IpfUd6wXINvksdhZQG62vKIuR8hMclQwC+HZP2AzHILU2rhKwuwS62iRkY40CAiHrSIaM8sdIKa2pHDMJmzjRMnQ6y/u5kheTpJRl7m8XcZH6OGvNoBS6hJTBPIwwpXeJdZd+P3CepTyOKUdPGomR1KYdoKRVd5MlwKAI5/vMmYWCRp7Zpb0FH2ML7KcJW6qXinzrNFPeehJwknTHmCxL45AtriMq77Skfkq8z8Ceb1vy1zDONqy4M4cj2nLVfvlfY7uC/6/1WJ+4ObRQchyVBB/NNLaEViZzwsO1vsUbda0evpkmeErSpeXqF9tilpDVdpW+UiZhNK8owHmHy7X8WMova72exnjsVU3GvQjgCwPmH8Nc13R+/wPCdB3EAAUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var chatActionsSendStyle = '_1y8950ed';
export var chatAvatarStyle = '_1y8950e9';
export var chatInputContainerAnimateStyle = '_1y8950eb';
export var chatInputContainerStyle = '_1y8950ea';
export var chatInputTextareaStyle = '_1y8950ec';
export var chatMessageContainerEndStyle = '_1y8950e3';
export var chatMessageContainerStartStyle = '_1y8950e4';
export var chatMessageContainerStyle = '_1y8950e2';
export var chatMessageStyles = '_1y8950e5';
export var chatMessageTextFillStyles = '_1y8950e8';
export var chatMessageTextStyles = '_1y8950e7';
export var chatMessageTitleStyles = '_1y8950e6';
export var chatMessagesContainerStyle = '_1y8950e1';
export var chatStyle = '_1y8950e0';