export { DocsLinksService } from './services/docs-links';

import { DocScope, type Framework, WorkspaceScope } from '@toeverything/infra';

import { DocsSearchService } from '../docs-search';
import { OrganizeService } from '../organize';
import { DocsLinksService } from './services/docs-links';

export function configureDocsLinksModule(framework: Framework) {
  framework
    .scope(WorkspaceScope)
    .scope(DocScope)
    .service(DocsLinksService, [DocsSearchService, OrganizeService]);
}
