import "../../core/src/plugins/ai-chat/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/plugins/ai-chat/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51U246bMBB9z1eMVK2USHUElJCt96V/Ujl4ALfGtgaTkFb59wocwqWblVohHmZ8xnPmnJH332O65jJpI/i9AXC2UV5Zw0GcGqtbj28bgJP13tYcksh1fUyqrPwY3jb78Y54uOOipK84pNFLj60wgF9DeBL5z5JsayTLrbbE4Sxoy5goCmWQzY4dqVrQNcB2gQZJJEZCqrbhEN/ZhDSH2HXQWK0kfMIUUzwuuCULbmkoHcmFeIb+MkcrUyEpPy+YpaRqnBZXDoXG4VKhVWmY8lg3HHI0HqlP/2gbr4p+HuPR+PnRarBDkOo9fTx2/qkyHKL94SHCUtfQYV7RsaYS0l44RK6D5P5TeRLb6PPw7aPDbunZc7fsGUmLK3PCoJ71mRaKUAuvzrjQOf1g67x1HFh8XOwcS5KVVYfhir9cuHsXR8s1HGMnpFSmnHa6r2NSEeaBSW51W5v/8XPGLRu4/YORhTWeNeoXruBDvmLZBLrcB8qiqM/pHjUOmWQrjY4Dj6GnxNySCDO2RiL1lQvw68qTybh3ZF7LRvbyVLNZj69Dj1pQqQxbPSffapRKQJMTogFhJGxr0bGHoUnqut1Qv3p4HqZnwePJ9WNI3Da3PzfmzBfwBAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var aiChatCloseBtnStyle = '_1rycd2u4';
export var aiChatContainerStyle = '_1rycd2u0';
export var aiChatIslandBtnContainerStyle = '_1rycd2u2';
export var aiChatIslandBtnStyle = '_1rycd2u3';
export var aiChatOnboardingApiKeyContainerStyle = '_1rycd2u5';
export var aiChatOnboardingApiKeyLinkStyle = '_1rycd2u7';
export var aiChatOnboardingApiKeyTitleStyle = '_1rycd2u6';
export var aiChatStyle = '_1rycd2u1';
export var aiChatUpdateApiKeyButtonStyle = '_1rycd2u9';
export var aiChatUpdateApiKeyWrapperStyle = '_1rycd2u8';