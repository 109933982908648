import {
  QueryClient,
  QueryClientProvider as Provider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PropsWithChildren, ReactNode } from 'react';

const queryClient = new QueryClient();

export const QueryClientProvider = (props: PropsWithChildren): ReactNode => {
  return (
    <Provider client={queryClient}>
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  );
};
