import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback } from 'react';

import { ViewService } from '../../services/view';
import { WorkbenchService } from '../../services/workbench';
import { useActiveSidebar } from '../use-is-active-sidebar';
import { ViewSidebarTabBodyTarget } from '../view-islands';
import * as styles from './sidebar-container.css';
import { Header } from './sidebar-header';
import { SidebarHeaderSwitcher } from './sidebar-header-switcher';

export const SidebarContainer = ({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
  const workbenchService = useService(WorkbenchService);
  const workbench = workbenchService.workbench;
  const viewService = useService(ViewService);
  const view = viewService.view;
  const sidebarTabs = useLiveData(view.sidebarTabs$);
  const activeSidebarTab = useLiveData(view.activeSidebarTab$);
  /* DNB_FIX */
  const { RIGHT_SIDEBAR, globalStateChange, isSharedMode } = useActiveSidebar();

  const handleToggleOpen = useCallback(() => {
    /*  DNB_FIX */
    if (!isSharedMode) globalStateChange(RIGHT_SIDEBAR, false);
    workbench.toggleSidebar();
  }, [RIGHT_SIDEBAR, globalStateChange, isSharedMode, workbench]);

  return (
    <div className={clsx(styles.sidebarContainerInner, className)} {...props}>
      <Header onToggle={handleToggleOpen}>
        <SidebarHeaderSwitcher />
      </Header>
      {sidebarTabs.length > 0 ? (
        sidebarTabs.map(sidebar => (
          <ViewSidebarTabBodyTarget
            tabId={sidebar.id}
            key={sidebar.id}
            style={{ display: activeSidebarTab === sidebar ? 'block' : 'none' }}
            viewId={view.id}
            className={clsx(
              styles.sidebarBodyTarget,
              !BUILD_CONFIG.isElectron && styles.borderTop
            )}
          />
        ))
      ) : (
        // DNB_FIX добавить перевод
        <div className={styles.sidebarBodyNoSelection}>Нет выбора</div>
      )}
    </div>
  );
};
