import type { DocMeta } from '@blocksuite/store';
import type { WorkspaceService, WorkspacesService } from '@toeverything/infra';
import {
  fromPromise,
  IndexedDBIndexStorage,
  Service,
} from '@toeverything/infra';
import type { Observable } from 'rxjs';
import { from, map, mergeMap } from 'rxjs';

import { blockIndexSchema, docIndexSchema } from '../../docs-search/schema';

export class WorkspacesSearchService extends Service {
  constructor(
    private readonly workspacesService: WorkspacesService,
    private readonly workspaceService: WorkspaceService
  ) {
    super();
  }

  getIndexStorage(workspaceId: string) {
    return new IndexedDBIndexStorage('idx:' + workspaceId);
  }

  search$(query: string): Observable<
    {
      docId: string;
      title?: string;
      score: number;
      blockId?: string;
      blockContent?: string;
      workspaceId: string;
      docMeta?: DocMeta;
    }[]
  > {
    const currentId = this.workspaceService.workspace.id;

    return this.workspacesService.list.workspaces$.pipe(
      map(value => from(value.filter(v => v.id !== currentId))),
      mergeMap(workspace$ => {
        return workspace$.pipe(
          mergeMap(w => {
            const indexStorage = this.getIndexStorage(w.id);
            const docIndex = indexStorage.getIndex('doc', docIndexSchema);
            const blockIndex = indexStorage.getIndex('block', blockIndexSchema);

            const workspace = this.workspacesService.open({
              metadata: w,
            }).workspace;

            const block$ = blockIndex
              .aggregate$(
                {
                  type: 'boolean',
                  occur: 'must',
                  queries: [
                    {
                      type: 'match',
                      field: 'content',
                      match: query,
                    },
                    {
                      type: 'boolean',
                      occur: 'should',
                      queries: [
                        {
                          type: 'all',
                        },
                        {
                          type: 'boost',
                          boost: 1.5,
                          query: {
                            type: 'match',
                            field: 'flavour',
                            match: 'affine:page',
                          },
                        },
                      ],
                    },
                  ],
                },
                'docId',
                {
                  pagination: {
                    limit: 50,
                    skip: 0,
                  },
                  hits: {
                    pagination: {
                      limit: 2,
                      skip: 0,
                    },
                    fields: ['blockId', 'flavour'],
                    highlights: [
                      {
                        field: 'content',
                        before: '<b>',
                        end: '</b>',
                      },
                    ],
                  },
                }
              )
              .pipe(
                mergeMap(({ buckets }) => {
                  return fromPromise(async () => {
                    const docData = await docIndex.getAll(
                      buckets.map(bucket => bucket.key)
                    );

                    const result = [];

                    for (const bucket of buckets) {
                      const firstMatchFlavour =
                        bucket.hits.nodes[0]?.fields.flavour;
                      if (firstMatchFlavour === 'affine:page') {
                        // is title match
                        const blockContent =
                          bucket.hits.nodes[1]?.highlights.content[0]; // try to get block content
                        result.push({
                          workspaceId: w.id,
                          docId: bucket.key,
                          title: bucket.hits.nodes[0].highlights.content[0],
                          score: bucket.score,
                          blockContent,
                          docMeta: workspace.docCollection.meta.getDocMeta(
                            bucket.key
                          ),
                        });
                      } else {
                        const title =
                          docData
                            .find(doc => doc.id === bucket.key)
                            ?.get('title') ?? '';
                        const matchedBlockId =
                          bucket.hits.nodes[0]?.fields.blockId;
                        // is block match
                        result.push({
                          docId: bucket.key,
                          docMeta: workspace.docCollection.meta.getDocMeta(
                            bucket.key
                          ),
                          workspaceId: w.id,
                          title: typeof title === 'string' ? title : title[0],
                          blockId:
                            typeof matchedBlockId === 'string'
                              ? matchedBlockId
                              : matchedBlockId[0],
                          score: bucket.score,
                          blockContent:
                            bucket.hits.nodes[0]?.highlights.content[0],
                        });
                      }
                    }

                    return result;
                  });
                })
              );

            workspace.dispose();

            return block$;
          })
        );
      })
    );
  }
}
