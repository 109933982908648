export function getFileNameFromContentDisposition(contentDisposition: string) {
  // Регулярное выражение для извлечения имени файла, учитывая различные варианты
  const regex = /filename=(?:'([^']+?)'|"([^"]+?)"|([^;\s]+))/gi;
  let match;
  let filename = '';

  // Ищем все вхождения filename и объединяем результаты
  while ((match = regex.exec(contentDisposition)) !== null) {
    filename += decodeURIComponent(match[1] || match[2] || match[3]);
  }

  return filename.trim();
}
