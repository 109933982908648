import { fetchCloud } from '@affine/core/modules/cloud';

import type { Board } from '../model';

export const getBoards = async () => {
  const result = await fetchCloud(`/api/miro/boards`);

  return (await result.json()) as Board[];
};

export const isAuthorized = async () => {
  const result = await fetchCloud(`/api/miro/auth/state`);

  return (await result.json()) as { data: boolean };
};

export const getFileImportBoard = async (board: Board) => {
  const result = await fetchCloud(`/api/miro/zip/${board.boardId}`, {
    timeout: Number.MAX_SAFE_INTEGER,
  });
  const buffer = await result.arrayBuffer();
  const blob = new Blob([buffer]);
  const file = new File([blob], board.name + '.zip', {
    type: 'application/zip',
  });

  return file;
};
