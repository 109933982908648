import { fromPromise, Service } from '@toeverything/infra';

import { fetchCloud } from '../utils';

export class FetchService extends Service {
  rxFetch = (
    input: string,
    init?: RequestInit & {
      // https://github.com/microsoft/TypeScript/issues/54472
      priority?: 'auto' | 'low' | 'high';
    } & {
      traceEvent?: string;
    }
  ) => {
    return fromPromise(signal => {
      return this.fetch(input, { signal, ...init });
    });
  };

  /**
   * fetch with custom custom timeout and error handling.
   */
  // DNB_FIX
  fetch = fetchCloud;
}
