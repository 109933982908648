import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Menu,
  MenuItem,
  MenuTrigger,
} from '@affine/component';
import { AffineSharedPageReference } from '@affine/core/components/affine/reference-link';
import { DocsLinksService } from '@affine/core/modules/docs-links';
import {
  GlobalContextService,
  useLiveData,
  useService,
  useServices,
  WorkspaceService,
} from '@toeverything/infra';
import { Fragment, useEffect, useState } from 'react';

export const Breadcrumbs = ({
  pageId,
  title,
  className,
}: {
  pageId: string;
  title: string | undefined;
  className?: string | undefined;
}) => {
  const docsLinks = useService(DocsLinksService);

  const [firstValue, setFirstValue] = useState<
    string | { title: string; id: string } | null
  >(null);
  const [lastValue, setLastValue] = useState<
    string | { title: string; id: string } | null
  >(null);
  const [menuItems, setMenuItems] = useState<
    (string | { title: string; id: string } | null)[][] | null
  >(null);

  const { workspaceService, globalContextService } = useServices({
    WorkspaceService,
    GlobalContextService,
  });

  const docCollection = workspaceService.workspace.docCollection;

  const docMode = useLiveData(globalContextService.globalContext.docMode.$);

  const shareParams = new URLSearchParams(docMode ? { mode: docMode } : {});

  useEffect(() => {
    async function getBreads() {
      const breadcrumbs = await docsLinks.breadcrumbsGet(pageId, title || '');

      const getItems = (
        array: (string | { title: string; id: string })[][]
      ) => {
        if (array.length === 0)
          return { firstValue: null, lastValue: null, menuItems: [] };
        const maxLength = Math.max(...array.map(arr => arr.length));
        const longestArray = array.filter(arr => arr.length === maxLength);
        const isAloneLongest = longestArray.length === 1;
        const firstValue =
          isAloneLongest && longestArray[0].length > 1
            ? longestArray[0][0]
            : null;
        const lastValue = longestArray[0][longestArray[0].length - 1];
        const menuItems: (string | { title: string; id: string } | null)[][] =
          [];

        const lengthForMenu =
          firstValue && isAloneLongest ? maxLength - 1 : maxLength;

        for (let i = 1; i < lengthForMenu; i++) {
          const currentRow = [];
          for (const arr of array) {
            currentRow.push(
              arr[arr.length - 1 - i] !== undefined
                ? arr[arr.length - 1 - i]
                : null
            );
          }
          menuItems.push(currentRow);
        }
        const menuItemsUnique = menuItems.reverse().map(menuItem => {
          const unique = Array.from(
            new Set(menuItem.map(resItem => JSON.stringify(resItem)))
          ).map(str => JSON.parse(str));
          return unique;
        });

        return {
          firstValue,
          lastValue,
          menuItemsUnique,
        };
      };

      const { firstValue, lastValue, menuItemsUnique } = getItems(breadcrumbs);
      setFirstValue(firstValue);
      setLastValue(
        typeof lastValue === 'object' && lastValue?.title === ''
          ? { title: 'Untitled', id: lastValue.id }
          : lastValue
      );
      setMenuItems(menuItemsUnique || []);
    }

    getBreads().catch(error => console.log(error));
  }, [docsLinks, pageId, title]);

  const items = (
    array: (
      | string
      | {
          title: string;
          id: string;
        }
      | null
    )[]
  ) => {
    return (
      <>
        {array.map(item => {
          return item ? (
            <MenuItem key={typeof item === 'object' ? item.id : item}>
              {typeof item === 'object' ? (
                <AffineSharedPageReference
                  pageId={item.id}
                  docCollection={docCollection}
                  params={shareParams}
                />
              ) : (
                <span>{item}</span>
              )}
            </MenuItem>
          ) : null;
        })}
      </>
    );
  };

  return (
    <Breadcrumb className={className}>
      <BreadcrumbList className="!flex">
        {firstValue && (
          <>
            <BreadcrumbItem>
              {typeof firstValue === 'object' ? (
                <AffineSharedPageReference
                  pageId={firstValue.id}
                  docCollection={docCollection}
                  params={shareParams}
                />
              ) : (
                <BreadcrumbPage>{firstValue}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        )}
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((menuItem, index) => (
            <Fragment key={index}>
              <BreadcrumbItem>
                <Menu items={items(menuItem)}>
                  <MenuTrigger style={{ border: 'none' }} breadcrumbs />
                </Menu>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
            </Fragment>
          ))}
        {lastValue && (
          <BreadcrumbItem>
            <BreadcrumbPage>
              {typeof lastValue === 'object' ? lastValue.title : lastValue}
            </BreadcrumbPage>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
