// DNB_FIX убрать из меню пункты AI
import { AffineSlashMenuWidget } from '@blocksuite/affine/blocks';

export function setupSlashMenuEntry(slashMenu: AffineSlashMenuWidget) {
  const menu = slashMenu.config.items.slice();

  slashMenu.config = {
    ...AffineSlashMenuWidget.DEFAULT_CONFIG,
    items: menu,
  };
}
