import type { FetchService } from '@affine/core/modules/cloud';

import type { AiChat } from '../model';
import { toTextStream } from '../utils';

export const getChat = async (userId: string, fetcher: FetchService) => {
  const result = await fetcher.fetch(`/api/ai/chat?userid=${userId}`);
  const chat = (await result.json()) as AiChat;

  return chat;
};

export const getMessage = async (userId: string, message: string) => {
  const eventSource = new EventSource(
    `/api/ai/chat/completions?userid=${userId}&message=${message}`
  );

  const abortController = new AbortController();
  const abortSignal = abortController.signal;

  const messagesQueue = [];
  for await (const event of toTextStream(eventSource, {
    timeout: 50000,
    signal: abortSignal,
  })) {
    if (event.type === 'message') {
      messagesQueue.push(event.data);
    }
  }

  return messagesQueue.join('');
};

export const deleteChat = async (userId: string, fetcher: FetchService) => {
  const result = await fetcher.fetch(`/api/ai/chat?userid=${userId}`, {
    method: 'DELETE',
  });

  return (await result.json()) as boolean;
};

export const getApiKey = async (userId: string, fetcher: FetchService) => {
  const result = await fetcher.fetch(`/api/ai/auth?userid=${userId}`);

  return await result.text();
};

export const updateApiKey = async (
  userId: string,
  apiKey: string,
  fetcher: FetchService
) => {
  const result = await fetcher.fetch(
    `/api/ai/auth?userid=${userId}&apikey=${apiKey}`,
    {
      method: 'POST',
    }
  );

  const updated = await result.text();

  return updated === 'true';
};
