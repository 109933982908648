// import { Button } from '@affine/component/ui/button';
// import { useI18n } from '@affine/i18n';
// import { Logo1Icon } from '@blocksuite/icons/rc';
// import {  useCallback } from 'react';

// import { DesktopNavbar } from './desktop-navbar';
import type { ReactNode } from 'react';

import { CookieBanner } from '../cookie-banner';
import * as styles from './index.css';
// import { MobileNavbar } from './mobile-navbar';

// DNB_FIX скрыть информацию
export const AffineOtherPageLayout = ({
  // isSmallScreen,
  children,
}: {
  children: ReactNode;
}) => {
  // const t = useAFFiNEI18N();

  // const openDownloadLink = useCallback(() => {
  //   open(BUILD_CONFIG.downloadUrl, '_blank');
  // }, []);

  return (
    <div className={styles.root}>
      {/* {BUILD_CONFIG.isElectron ? (
        <div className={styles.draggableHeader} />
      ) : (
        <div className={styles.topNav}>
          <a href="/" rel="noreferrer" className={styles.affineLogo}>
            <Logo1Icon width={24} height={24} />
          </a>

          <DesktopNavbar />
          <Button
            onClick={openDownloadLink}
            className={styles.hideInSmallScreen}
          >
            {t['com.affine.auth.open.affine.download-app']()}
          </Button>
          <MobileNavbar />
        ) : (
          <>
            <DesktopNavbar />
            <Button onClick={openDownloadLink}>
              {t['com.affine.auth.open.affine.download-app']()}
            </Button>
          </>
        )}
      </div>  */}

      {children}
      <CookieBanner />
    </div>
  );
};
