import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export * from './observe-resize';
export { startScopedViewTransition } from './view-transition';
export * from './with-unit';
