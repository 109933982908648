import { Permission } from '@affine/graphql';
import { useI18n } from '@affine/i18n';

export const usePermission = () => {
  const t = useI18n();

  const getName = (permission: Permission): string => {
    switch (permission) {
      case Permission.Owner:
        return t['Workspace Owner']();
      case Permission.Admin:
        return '';
      case Permission.Read:
        return t['Reader']();
      case Permission.Write:
        return t['Editor']();
      default:
        return '';
    }
  };

  return { getName };
};
