import { Service } from '@toeverything/infra';

import type { DocsSearchService } from '../../docs-search';
import type { OrganizeService } from '../../organize';

export class DocsLinksService extends Service {
  constructor(
    private readonly searchService: DocsSearchService,
    private readonly organizeService: OrganizeService
  ) {
    super();
  }

  linksAll = async (
    links: { docId: string; title: string; blockId?: string }[],
    linkGet: (docId: string) => Promise<
      {
        docId: string;
        title: string;
      }[]
    >
  ) => {
    if (!links || links.length === 0) return;
    const allLinks: string[] = [];
    const uniqueLinks = new Set<string>();

    const getNested = async (docsId: string[]) => {
      const currentLevelDocs = [];
      for (const doc of docsId) {
        if (!uniqueLinks.has(doc)) {
          allLinks.push(doc);
          uniqueLinks.add(doc);
          currentLevelDocs.push(doc);
        }
      }
      for (const currentLevelDoc of currentLevelDocs) {
        const nestedDocs = await linkGet(currentLevelDoc);
        await getNested(nestedDocs.map(nestedDoc => nestedDoc.docId));
      }
    };
    await getNested(links.map(link => link.docId));
    return allLinks;
  };

  linksGetByDocId = async (docId: string) => {
    const childLinks = await this.searchService.searchRefsFrom(docId);
    return childLinks;
  };

  backlinksGetByDocId = async (docId: string) => {
    const parentLinks = await this.searchService.searchRefsTo(docId);
    return parentLinks;
  };

  neighborLinks = async (docId: string) => {
    const backlinks = await this.backlinksGetByDocId(docId);
    if (!backlinks) return;
    const neigbors = [];

    for (const backlink of backlinks) {
      const links = await this.linksGetByDocId(backlink.docId);
      neigbors.push(links.filter(item => item.docId !== docId));
    }
    return neigbors;
  };

  findAllPath = async (
    doc: { docId: string; title: string },
    currentPath: { docId: string; title: string }[] = []
  ) => {
    const newPath = [...currentPath, { docId: doc.docId, title: doc.title }];

    const backlinks = await this.backlinksGetByDocId(doc.docId);
    if (backlinks.length === 0) {
      return [newPath];
    }

    const allPath: { docId: string; title: string }[][] = [];
    for (const backLink of backlinks) {
      const paths = await this.findAllPath(backLink, newPath);
      if (Array.isArray(paths)) {
        allPath.push(...paths);
      } else {
        console.log('Error', paths);
      }
    }

    return allPath;
  };

  getPathByBackLinks = async (
    initialLinks: { docId: string; title: string }[]
  ) => {
    const allPaths = [];
    for (const backlink of initialLinks) {
      const paths = await this.findAllPath(backlink);
      if (Array.isArray(paths)) {
        allPaths.push(...paths);
      } else {
        console.log('Error', paths);
      }
    }
    return allPaths;
  };

  pathsWithFoldersGet = (
    docId: string,
    title: string,
    data?: { docId: string; title: string }[][]
  ) => {
    const path = [];
    const folderFirst = this.organizeService.folderTree.getFolder(docId);
    if (folderFirst.length > 0) {
      const allFolders = this.organizeService.getAllPathFolders(
        folderFirst?.[0]?.id
      );
      path.push([...allFolders, title]);
    }
    if (data) {
      data.forEach(item => {
        let allFolders: string[] = [];
        const docPath: string[] = [];
        for (const dataItem of item) {
          docPath.push(dataItem.title);
          const folderFirst = this.organizeService.folderTree.getFolder(
            dataItem.docId
          );
          if (folderFirst && folderFirst.length > 0) {
            allFolders = this.organizeService.getAllPathFolders(
              folderFirst?.[0]?.id
            );
            const reverseDocs = [...docPath].reverse();
            path.push([...allFolders, ...reverseDocs]);
          }
        }
      });
    }
    return path;
  };

  pathsWithoutFoldersGet = (
    data: {
      docId: string;
      title: string;
    }[][]
  ) => {
    const allDocs: { title: string; id: string }[][] = [];
    if (data) {
      data.forEach(item => {
        const docPath: { title: string; id: string }[] = [];
        for (const dataItem of item) {
          docPath.push({ title: dataItem.title, id: dataItem.docId });
        }
        allDocs.push(docPath.reverse());
      });
    }
    return allDocs;
  };

  breadcrumbsGet = async (docId: string, title: string) => {
    const allPaths = await this.getPathByBackLinks([
      { docId: docId, title: title },
    ]);
    let result = [];
    const pathsWithFolders = this.pathsWithFoldersGet(docId, title, allPaths);
    if (pathsWithFolders.length === 0) {
      const paths = this.pathsWithoutFoldersGet(allPaths);
      result = [...paths];
    } else result = pathsWithFolders;
    const unique = Array.from(
      new Set(result.map(resItem => JSON.stringify(resItem)))
    ).map(str => JSON.parse(str));
    return unique;
  };
}
