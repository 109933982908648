import { type ReactNode, useEffect } from 'react';

import { useTypingEffect } from '../hooks';

export interface TextTypingEffectOptons {
  message: string;
  render?: (typingText: string) => ReactNode;
  onComplete?: () => void;
  onChange?: () => void;
  isTypeByLetter?: boolean;
  duration?: number;
}

export const TextTypingEffect = ({
  message,
  duration = 100,
  isTypeByLetter,
  onChange,
  onComplete,
  render = typingText => <span>{typingText}</span>,
}: TextTypingEffectOptons) => {
  const typingText = useTypingEffect(message, duration, isTypeByLetter);

  useEffect(() => {
    if (message && typingText && message === typingText) {
      onComplete?.();
    }

    onChange?.();
  }, [typingText, message, onComplete, onChange]);

  return render(typingText);
};
