import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactNode, useEffect, useRef } from 'react';

import { Avatar } from '../../ui/avatar';
import * as styles from './index.css';
import type { Message, User } from './types';

export interface RenderTextMessageOptions {
  render: (text: string) => ReactNode;
  message: Message;
}

export interface ChatMessagesProps {
  messages?: Message[];
  currentUser: User;
  renderTextMessage?: (
    text: string,
    options: RenderTextMessageOptions
  ) => ReactNode;
}

const defaultRenderTextMessage = (text: string) => {
  return <span>{text}</span>;
};

export function ChatMessages({
  messages,
  currentUser,
  renderTextMessage = defaultRenderTextMessage,
}: ChatMessagesProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={styles.chatMessagesContainerStyle}>
      <div
        ref={messagesContainerRef}
        className={styles.chatMessagesContainerStyle}
      >
        <AnimatePresence>
          {messages?.map((message, index) => (
            <motion.div
              key={index}
              layout
              initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
              animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
              exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
              transition={{
                opacity: { duration: 0.1 },
                layout: {
                  type: 'spring',
                  bounce: 0.3,
                  duration: messages.indexOf(message) * 0.05 + 0.2,
                },
              }}
              style={{
                originX: 0.5,
                originY: 0.5,
              }}
              className={clsx(
                styles.chatMessageContainerStyle,
                message.user.id !== currentUser.id
                  ? styles.chatMessageContainerStartStyle
                  : styles.chatMessageContainerEndStyle
              )}
            >
              <div className={styles.chatMessageStyles}>
                {message.user.id !== currentUser.id && (
                  <Avatar
                    avatarProps={{ className: styles.chatAvatarStyle }}
                    size={30}
                    url={message.user.avatar}
                    name={message.user.name}
                  />
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {message.user.id !== currentUser.id && (
                    <span className={styles.chatMessageTitleStyles}>
                      {message.user.name}
                    </span>
                  )}
                  <div
                    className={clsx(
                      styles.chatMessageTextStyles,
                      styles.chatMessageTextFillStyles
                    )}
                  >
                    {renderTextMessage(message.message, {
                      render: defaultRenderTextMessage,
                      message: message,
                    })}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}
