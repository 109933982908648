import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWbW/bIBD+nl+BKk1qpBLhvHStq/6QadoHjM82CwYLsJN02n+fjB2HONhrp+6bDdxxz3PHc7diUb0RFUG/FggVwPPCxmhHquPLAqGUm0rQU4wyAW6BCp5LzC2UJkYMpAXdLue0itFTZ1PRNOUyjxFBj91KonQKGmua8trEaNutqtoKLiFGUkloFw48tUWMIkK+tL8lPeJ+aUf6eJgSSseoofoeY5plXAJu1tjC0eJK85Lq0/Jl8Xux6kHFhWpAO2is1qa1rRQ/R51Qts+1qmU6cumssLvMdxc5R31MpPXQ0hKj6J+pUoZbrmSMNAhqeQPebWt3W+sNm0JzuXd3Dtsbt92jwdCAtObCpaoo4/YUI7J68o0ChKSQ0VrYMSGdp8Fye1Uf23WfwwZ0JtQhRgVPU5Cewc4Z3LDi8KRcA+uAMyXqUt7w1cG2VLu4rKbSZEqXcfcpqIVv92Q57PUsDucQWe0MYnXCGU7gjYO+X213D6t19EAeoiUixof2PaWWYsraBLzeWV3D3Q90BSMcAG5p8Avk0S+Qcx07KLluSYrCnN3y+gHaftbG8uyEmZIWpPVLzaemL4jPI+ax27SnCl7vpNIlFXc/HP5L7U37yqgwU866Wp5z9rV7GkpabPgbjF5vt1666hDuOffsrnt23YHDIHXuJdcGNDYggFnvFQVS5bTmsgFC8Mpw4wSs4BawqShzqnbQtAro2pBbLl10iVBs74F7Cj8cpxs9gAlpmSXkaAKEkAAh246QkNQmgrI93pHlVbimya/qvlf98yXn/5BDzpTEBpiS6Ui6n/8PC3NlEQrQZXsI8LYlPH8qeg/wuCro30t+LsNeU+57T8nl6NRwV3Kl9ee6nSrjd/e7wT/7aGuY0TivA880o3RaS84zQvhk5J2Erm2GR5BALr1kRdsrArJLU+nlmQqByCraGATU+NMQ8YtpQ6babjsu6ZxLLCCzvdV7MzTDbiUog1uLOQ4uY9jU4JeodqKhaRv/WWn9ycPxUlEN0jX/m8FtKnWZ/w4369vLrrWstrZNmdtbzo6DzXo4HZKqvJ/EhlwmRonaOly6S1w4H/4oGMzNFNLxjYHpsRjrRbSdHaFd7lx3phbSFt4f3t3EKRkMAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeContainer = 'c1u3lp2';
export var activeIcon = 'c1u3lph';
export var collaborationIcon = 'c1u3lpe';
export var container = 'c1u3lp0';
export var containerWorkspaceInfo = 'c1u3lpb';
export var disable = 'c1u3lp3';
export var enableCloudButton = 'c1u3lpd';
export var infoContainer = 'c1u3lp1';
export var settingButton = 'c1u3lpf';
export var showOnCardHover = 'c1u3lpg';
export var workspaceActiveStatus = 'c1u3lp9';
export var workspaceInfo = 'c1u3lp6';
export var workspaceInfoSlide = 'c1u3lp5';
export var workspaceInfoSlider = 'c1u3lp4';
export var workspaceInfoTooltip = 'c1u3lpa';
export var workspaceName = 'c1u3lp7';
export var workspaceStatus = 'c1u3lp8';
export var workspaceTitleContainer = 'c1u3lpc';