import { SendIcon } from '@blocksuite/icons/rc';
import { nanoid } from 'nanoid';
import type React from 'react';
import { type ReactNode, useEffect, useRef, useState } from 'react';

import { IconButton } from '../../ui/button';
import { Textarea, useAutosizeTextarea } from '../../ui/textarea';
import * as styles from './index.css';
import type { Message } from './types';

export interface ChatInputProps {
  sendMessage: (newMessage: Message) => void;
  actionsLeft?: () => ReactNode;
}

export function ChatInput({ sendMessage, actionsLeft }: ChatInputProps) {
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [triggerAutoSize, setTriggerAutoSize] = useState('');

  useAutosizeTextarea({
    textAreaRef: textareaRef.current,
    triggerAutoSize: triggerAutoSize,
  });

  useEffect(() => {
    setTriggerAutoSize(message as string);
  }, [message]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textMessage = event.target.value;

    setTriggerAutoSize(textMessage);
    setMessage(textMessage);
  };

  const handleSend = () => {
    if (message.trim()) {
      const newMessage: Message = {
        id: nanoid(),
        user: {
          id: '',
          name: '',
        },
        message: message.trim(),
        status: 'done',
      };
      sendMessage(newMessage);
      setMessage('');

      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setMessage(prev => prev + '\n');
    }
  };

  return (
    <div className={styles.chatInputContainerStyle}>
      {actionsLeft?.()}
      <Textarea
        autoComplete="off"
        value={message}
        ref={textareaRef}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        name="message"
        placeholder="Отправить сообщение"
        className={styles.chatInputTextareaStyle}
      ></Textarea>

      <IconButton
        onClick={handleSend}
        className={styles.chatActionsSendStyle}
        disabled={!message}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
}
