import type { SelectProps } from '@affine/component/ui/select';
import { Select, SelectItem } from '@affine/component/ui/select';
import { Permission } from '@affine/graphql';
import { useI18n } from '@affine/i18n';

export interface SelectPermissionsProps extends SelectProps {
  value?: Permission;
  onChange?: (value: Permission) => void;
  //DNB_FIX
  permissions?: Permission[] | null;
}

export const SelectPermissions = ({
  selectRoot,
  value,
  onChange,
  //DNB_FIX
  permissions,
}: SelectPermissionsProps) => {
  const t = useI18n();
  //DNB_FIX возможность добавлять пользователей для не Owner
  const renderSelectItem = () => {
    const permissionArray = permissions
      ? permissions
      : [Permission.Read, Permission.Write];
    return permissionArray?.map(item => (
      <SelectItem key={item} value={item}>
        {t[item === Permission.Read ? 'Reader' : 'Editor']()}
      </SelectItem>
    ));
  };

  return (
    <Select
      selectRoot={{
        value,
        defaultValue: Permission.Read,
        onValueChange: onChange,
        ...selectRoot,
      }}
    >
      {/* DNB_FIX */}
      {renderSelectItem()}
      {/*
        <>
          <SelectItem value={Permission.Write}>{t['Editor']()}</SelectItem>
          <SelectItem value={Permission.Read}>{t['Reader']()}</SelectItem>
        </>*/}
    </Select>
  );
};
