import { ArrowDownSmallIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import { forwardRef, type Ref } from 'react';

import { BreadcrumbEllipsis } from '../breadcrumbs';
import { Button, type ButtonProps } from '../button';

//DNB_FIX breadcrumbs
export interface MenuTriggerProps extends ButtonProps {
  breadcrumbs?: boolean;
}

export const MenuTrigger = forwardRef(function MenuTrigger(
  {
    children,
    className,
    contentStyle,
    breadcrumbs,
    ...otherProps
  }: MenuTriggerProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <Button
      ref={ref}
      suffix={
        breadcrumbs ? (
          <BreadcrumbEllipsis className="h-4 w-4" />
        ) : (
          <ArrowDownSmallIcon />
        )
      }
      className={clsx(className)}
      contentStyle={{ width: 0, flex: 1, textAlign: 'start', ...contentStyle }}
      {...otherProps}
    >
      {children}
    </Button>
  );
});
