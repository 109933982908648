import { Button } from '@affine/component';
import { SettingRow } from '@affine/component/setting-components';
import { AuthService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { useEnabledAiChat } from './hooks';
import { aiChatAtom } from './model';

export const AiChatPanel = () => {
  const t = useI18n();

  const session = useService(AuthService).session;
  const account = useLiveData(session.account$);

  const setAIChatModal = useSetAtom(aiChatAtom);

  const { existApiKey, enabled } = useEnabledAiChat(account?.id);

  const title = useMemo(
    () =>
      existApiKey
        ? t['dnb.ai.api-key.title.update']()
        : t['dnb.ai.api-key.title.add'](),
    [existApiKey, t]
  );

  const onUpdateApiKeyButtonClick = useCallback(() => {
    setAIChatModal({
      openModal: true,
      state: 'updateAPIKey',
      userId: account?.id,
    });
  }, [account?.email, setAIChatModal, account?.id]);

  return (
    enabled && (
      <SettingRow name={'AI'} desc={`${title} API-ключ для работы с AI`}>
        <Button onClick={onUpdateApiKeyButtonClick}>{title} API-ключ</Button>
      </SettingRow>
    )
  );
};
