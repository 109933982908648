import "../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VXXW/bIBR9769Akya1UonsNMk2+mMmbK5tWgwISOO02n+fwHaMidOua/ewl0jm436ce+7hZvVzWz86+pChlyuEGLda0CNBlYDu/gohKngtMXfQ2n4Rg2R+o6Wm5hI7pQnK17q7v/p1tRps5cFWOF0bdSAov3/TdgnSgYksC6gcQetsbnodTB84cw1BeZZ9/QvLIeb1RoejNfUJZP1HCPlg/JL/jR3fxY63u/58A7xu3PRdKMPAYEMZ31uCtn14WlnuuJIEGRDU8Sfwq+XeWGUI0oqP8QX3tjFcPhKUzbyvGLe0EBCiGK8yqOheuN5HsILhCaSzBEklYWaANOoJDFqVtAVDMS+VDJlqMEuV/5Sbl+98EEdaWCX2DmbF71NG6GFvHa+OuFTSgXQxAS7woqDlY23UXjJcKuGRNXVBr3fZLdrlt2h3d4uy1fbGH33GXDLoBkoPp5+oucaYVhWXgA8NdxDOVko6bPkzjGyboNnEKJRUlNeeywij3UZ3CKN8q7ubxWR8W/gNB53DIZ9xLbZuNZVLneIrWQnfkg1nDOTJ0rQBQnBtufVbIRdsNS3B49v3xHLaWtASGiV83cL+BMFhrG424/Q2BLjcHErTkrsjQdlqs9y5kR1CaOUGYp1w+vLl/jz9kWfj9zKjeuXJAjbeYRa3q1QedqEOwOIgdsvy+X4uLmEbKmShVJJRc4zgDeI1CJmmjHFZE/R90LKJfTNjYb2zN3H03/pSjBY2i13Yq3zcLYnlqI204e081N4YQbnukFWCs+Ru72s6fwZlEEfGDZR9xUol9q1cBDkQFhfgDuApPuX5Y55n5jNFGcpTGRogPIshodNiCSNvg3K+vAZaOHKG0wn073Ou/yBSuWsiqHVYVdgdNdwE+0ODFMo51RK0m1+jsRast9mF9C5DnL5MySs9UmNYND2M6dtdzNHPozc3GhM+R6JeZb9tZ+wvVhqkj2lQkAVxS+g83S37UedNb6809nmvfA4EItBroPQ6GdHY23EHjXinIP2LyBMWQdrD/q1carvxuwoXTi9KvjQuUSFiE/W/QSdp0iTu5o9G27MR9a8GnrnI7JIXZK2700j8hm73FbzL0oHn/YCdt8ECDS5QZgKRf7xy0OqGWm6jSBZm9Zn+bRJB5dLHHsf1MJ8P+gP41f8r0+XHNKnxwfpQDpN9cVH4wuCejHPDRJu0Zfs/DkC/AfOYJhH9DgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRight = '_5gktajk';
export var avatarWrapper = '_5gktaj3';
export var fakeWrapper = '_5gktaj5';
export var goUpgrade = '_5gktaji';
export var goUpgradeWrapper = '_5gktajj';
export var iconButton = '_5gktajf';
export var label = '_5gktajg';
export var labelWrapper = '_5gktaj2';
export var memberContainer = '_5gktaja';
export var memberEmail = '_5gktajd';
export var memberList = '_5gktaj8';
export var memberListItem = '_5gktaj9';
export var memberName = '_5gktajc';
export var memberPermision = '_5gktaje';
export var membersEmpty = '_5gktajm';
export var membersFallback = '_5gktaj6';
export var membersPanel = '_5gktaj7';
export var profileHandlerWrapper = '_5gktaj1';
export var profileWrapper = '_5gktaj0';
export var roleOrStatus = '_5gktajb';
export var searchIcon = '_5gktajl';
export var urlButton = '_5gktaj4';
export var workspaceLabel = '_5gktajh';