import { atom } from 'jotai';

export interface AiChat {
  id?: string;
  chat?: ApiChat;
}

type ApiChat = {
  id: string;
  history: ApiChatHistory;
  messages: ApiChatMessage[];
  models: string[];
  params: any;
  tags: any[];
  timestamp: number;
  title: string;
};

type ApiChatMessageNode = {
  childrenIds: any[];
  content: string;
  id: string;
  parentId: string | null;
  role: 'user' | 'assistant';
  timestamp: number;
};

type ApiChatMessage = ApiChatUserMessage | ApiChatAssistantMessage;

type ApiChatHistory = {
  currentId: string;
  messages: Record<string, ApiChatMessage>;
};

type ApiChatUserMessage = ApiChatMessageNode & {
  models: string[];
};

type ApiChatAssistantMessage = ApiChatMessageNode & {
  model: string;
  modelName: string;
  userContext: string | null;
  done?: boolean | undefined;
  info?: ApiChatAssistantMessageInfo | undefined;
};

type ApiChatAssistantMessageInfo = {
  total_duration: number;
  load_duration: number;
  prompt_eval_count: number;
  prompt_eval_duration: number;
  eval_count: number;
  eval_duration: number;
};

export type Account = {
  id: string;
  info?: {
    name: string;
  } | null;
  avatar?: string | null;
};

type ErrorEventSource = {
  message: string;
  name: string;
};

export const isErrorEventSource = (
  error: Record<string, unknown>
): error is ErrorEventSource => {
  return 'message' in error && 'name' in error;
};

export const apiKeyAtom = atom<string>('');

export type AIChat = {
  state: 'updateAPIKey' | null;
  userId?: string;
  setAIChatState: (state: AIChat['state']) => void;
  onClose: () => void;
};

export type AIChatAtom = {
  openModal: boolean;
  state: AIChat['state'];
  userId?: AIChat['userId'];
};

export const aiChatAtom = atom<AIChatAtom>({
  openModal: false,
  state: null,
  userId: '',
});
