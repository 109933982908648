import { atom } from 'jotai';

export type Board = {
  id: number;
  boardId: string;
  name: string;
  picture: {
    imageURL?: string;
  };
};

export type MiroAtom = {
  openModal: boolean;
};

export const miroAtom = atom<MiroAtom>({
  openModal: false,
});
